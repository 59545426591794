// @flow

import { gql } from "@apollo/client";

export default gql`
  query orders(
    $first: Int!
    $after: String
  ) {
    ordersConnection: ordersToBeArtworkReviewed(
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;
