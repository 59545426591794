// @flow

import * as React from "react";
import {
  ArrowBack,
  ArrowForward,
  ThumbDown,
  ThumbUp
} from "@material-ui/icons";
import { Container, Fab, Typography } from "@mui/material";
import { compose, setDisplayName, withHandlers } from "recompose";
import { graphql } from "@apollo/client/react/hoc";
import { query } from "../../../ArtReviewApp/components/ArtReviewOrderDetails/graph";
import { useState } from "react";
import { withRouter } from "found";
import { withStyles } from "@mui/styles";
import Divider from "@mui/material/Divider";
import markOrderAsArtworkChangeRequested from "../../../../../graph/mutations/markOrderAsArtworkChangeRequested";
import markOrderAsArtworkReviewed from "../../../../../graph/mutations/markOrderAsArtworkReviewed";
import markOrderAsProofCompleted from "../../../../../graph/mutations/markOrderAsProofCompleted";
import withSnackbar from "../../../../../components/withSnackbar";
import type { HOC } from "recompose";

type Props = {|
  +classes?: {|
    +absoluteWrapper: string,
    +artworkUrl: string,
    +content: string,
    +customizationAreaContainer: string,
    +customizationAreaDetail: string,
    +customizationAreaDivider: string,
    +customizationAreaImageItem: string,
    +divider: string,
    +missingArtworkLabel: string,
    +nextButton: string,
    +notesContainer: string,
    +notesList: string,
    +previewImage: string,
    +reviewingActionButton: string,
    +spinnerWrapper: string,
    +templateCaption: string
  |},
  +orderId: string
|};

const styles = theme => ({
  absoluteWrapper: {
    position: "absolute",
    top: 48,
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },

  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    margin: "25px"
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  },

  missingArtworkLabel: {
    color: "red"
  },

  notesContainer: {
    marginLeft: "30px"
  },

  customizationAreaDivider: {
    marginTop: "15px"
  },

  artworkUrl: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.primary.dark
    }
  },

  templateCaption: {
    color: theme.palette.primary.dark,
    margin: "0"
  },

  customizationAreaDetail: {
    margin: "0"
  },

  divider: {
    marginTop: "10px"
  },

  previewImage: {
    maxWidth: "100%",
    maxHeight: "100%"
  },

  notesList: {
    marginTop: "0"
  },

  customizationAreaContainer: {
    display: "flex"
  },

  customizationAreaImageItem: {
    flex: 1
  },

  nextButton: {
    marginLeft: "20px"
  },

  reviewingActionButton: {
    marginTop: "20px",
    marginBottom: "20px"
  },

  summaryDivider: {
    marginTop: "20px",
    marginBottom: "20px"
  }
});

const enhancer: HOC<*, Props> = compose(
  setDisplayName("ArtReviewOrderDetails"),

  withRouter,

  markOrderAsArtworkReviewed,

  markOrderAsProofCompleted,

  markOrderAsArtworkChangeRequested,

  withSnackbar,

  graphql(query, {
    skip: ({ orderId }) => !orderId
  }),

  withStyles(styles),

  withHandlers({
    handleOrderDetailRequestClose:
      ({ router }) =>
      () => {
        router.push("/apps/art-review");
      },

    handleReviewArtworkButtonClick:
      ({
        markOrderAsArtworkReviewed,
        markOrderAsProofCompleted,
        orderId,
        router,
        showErrorSnackbar,
        showSuccessSnackbar,
        onComplete
      }) =>
      () => {
        markOrderAsProofCompleted({ variables: { orderId } })
          .then(
            ({
              data: {
                markOrderAsProofCompleted: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                markOrderAsArtworkReviewed({ variables: { orderId } })
                .then(
                  ({
                    data: {
                      markOrderAsArtworkReviewed: { succeeded, errors }
                    }
                  }) => {
                    if (succeeded) {
                      showSuccessSnackbar(`The order has passed art review.`);
                      router.push("/apps/art-review");
                    } else {
                      showErrorSnackbar(
                        `Something went wrong: ${errors.orderId.join(", ")}`
                      );
                    }
                  }
                )
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => {
            if (onComplete) {
              onComplete();
            }
          });
      },

    handleArtworkReviewFailedButtonClick:
      ({
        markOrderAsArtworkChangeRequested,
        orderId,
        router,
        showErrorSnackbar,
        showSuccessSnackbar,
        onComplete
      }) =>
      () => {
        markOrderAsArtworkChangeRequested({
          variables: { orderId, description: "Art automation failed" }
        })
          .then(
            ({
              data: {
                markOrderAsArtworkChangeRequested: { succeeded, errors }
              }
            }) => {
              if (succeeded) {
                showSuccessSnackbar(`The order has failed art review.`);
                router.push("/apps/art-review");
              } else {
                showErrorSnackbar(
                  `Something went wrong: ${errors.orderId.join(", ")}`
                );
              }
            }
          )
          .catch(e => {
            showErrorSnackbar(`Something went wrong: ${e.message}`);
          })
          .finally(() => {
            if (onComplete) {
              onComplete();
            }
          });
      }
  })
);

const ArtReviewOrderDetails = ({
  classes,
  data,
  handleReviewArtworkButtonClick,
  handleArtworkReviewFailedButtonClick
}) => {
  const [passing, setPassing] = useState(true);
  const [currentState, setCurrentState] = useState("PREVIEW");
  const [customizationAreaIndex, setCustomizationAreaIndex] = useState(0);

  const productionJobs = data?.order?.productionJobs || [];
  const orderNumber = data?.order?.orderNumber;
  const customizationAreas = productionJobs
    .flatMap(
      ({ customizationAreas }) => customizationAreas
    )
    .filter(({ gangSheet }) => !gangSheet);

  const handleStartReviewingClick = () => {
    setCurrentState("REVIEWING");
    setCustomizationAreaIndex(0);
  };

  const handlePreviousClick = () => {
    setCustomizationAreaIndex(customizationAreaIndex - 1);
  };

  const handleNextClick = () => {
    if (customizationAreaIndex < customizationAreas.length - 1) {
      setCustomizationAreaIndex(customizationAreaIndex + 1);
    } else {
      setCurrentState("SUMMARY");
    }
  };

  const handlePassClick = () => {
    handleNextClick();
  };

  const handleFailClick = () => {
    setPassing(false);
    handleNextClick();
  };

  const handleBackToSummaryClick = () => {
    setCurrentState("PREVIEW");
    setCustomizationAreaIndex(0);
  };

  const handleCompleteReviewClick = () => {
    if (passing) {
      setCurrentState("PREVIEW");
      setCustomizationAreaIndex(0);
      handleReviewArtworkButtonClick();
    } else {
      setCurrentState("PREVIEW");
      setCustomizationAreaIndex(0);
      setPassing(true);
      handleArtworkReviewFailedButtonClick();
    }
  };

  if (currentState === "PREVIEW") {
    return (
      <Container maxWidth="lg">
        <SummaryView
          orderNumber={orderNumber}
          customizationAreas={customizationAreas}
          classes={classes}
        />
        <Fab
          color="primary"
          variant="extended"
          onClick={handleStartReviewingClick}
        >
          Start Reviewing
        </Fab>
      </Container>
    );
  }

  if (currentState === "REVIEWING") {
    return (
      <Container maxWidth="lg">
        <CustomizationAreaDetailView
          customizationArea={customizationAreas[customizationAreaIndex]}
          classes={classes}
          index={customizationAreaIndex}
        />
        <a href="#" onClick={handleBackToSummaryClick}>
          Back to summary
        </a>
        <br />
        {customizationAreaIndex > 0 && (
          <Fab color="primary" variant="extended" onClick={handlePreviousClick}>
            <ArrowBack /> Previous
          </Fab>
        )}
        <Fab color="primary" variant="extended" onClick={handleNextClick}>
          <ArrowForward /> Next
        </Fab>
        <Fab color="primary" variant="extended" onClick={handlePassClick}>
          <ThumbUp /> Pass
        </Fab>
        <Fab color="primary" variant="extended" onClick={handleFailClick}>
          <ThumbDown /> Fail
        </Fab>
      </Container>
    );
  }

  if (currentState === "SUMMARY") {
    return (
      <Container maxWidth="lg">
        <SummaryView
          orderNumber={orderNumber}
          customizationAreas={customizationAreas}
          classes={classes}
        />
        <Fab
          color="primary"
          variant="extended"
          onClick={handleCompleteReviewClick}
        >
          Complete Review - {passing ? "Artwork Uploaded" : "Needs Artwork"}
        </Fab>
      </Container>
    );
  }

  return null;
};

const SummaryDetailView = ({ customizationArea, index, classes }) => {
  const { method, gangsheet, width, height, quantity } = customizationArea;
  const product =
    method === "TRANSFER_STANDARD"
      ? "DTF Standard Transfers"
      : method === "TRANSFER_GLITTER"
        ? "DTF Glitter Transfers"
        : method === "TRANSFER_UV"
          ? "UV DTF 3D PermaStickers™"
          : null;
  const label = `${product} ${gangsheet ? "Gangsheet" : "by Size"} ${width}" x ${height}"`;

  const customerArtwork = customizationArea.customerArtwork[0];
  const artwork = customizationArea.productionArtwork[0];

  const customerArtworkUrl = customerArtwork?.file?.asset?.url?.formatted;
  const artworkUrl = artwork?.remoteAsset?.url?.formatted;

  console.log("customizationArea", customizationArea);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className={classes.customizationAreaContainer}>
          <div className={classes.customizationAreaImageItem}>
            {customerArtwork ? (
              <img
                style={{
                  maxWidth: "100px",
                  backgroundImage:
                    "linear-gradient(45deg, #b1b1b1 25%, transparent 25%), linear-gradient(-45deg, #b1b1b1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b1b1b1 75%), linear-gradient(-45deg, transparent 75%, #b1b1b1 75%)",
                  backgroundSize: "20px 20px",
                  backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px"
                }}
                src={customerArtworkUrl}
                alt="customerArtworkUrl"
              />
            ) : (
              <Typography className={classes.missingArtworkLabel}>
                Missing artwork
              </Typography>
            )}
          </div>
          <div className={classes.customizationAreaImageItem}>
            {artworkUrl ? (
              <img
                style={{
                  maxWidth: "100px",
                  backgroundImage:
                    "linear-gradient(45deg, #b1b1b1 25%, transparent 25%), linear-gradient(-45deg, #b1b1b1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b1b1b1 75%), linear-gradient(-45deg, transparent 75%, #b1b1b1 75%)",
                  backgroundSize: "20px 20px",
                  backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px"
                }}
                src={artworkUrl}
                alt="artworkUrl"
              />
            ) : (
              <Typography className={classes.missingArtworkLabel}>
                Missing artwork
              </Typography>
            )}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 6 }}>
          <Typography>{label}</Typography>
          <Typography variant="subtitle1">
            Line Item: {index}, Quantity: {quantity}
          </Typography>
        </div>
      </div>
      <Divider className={classes.summaryDivider} />
    </>
  );
};

const SummaryView = ({ classes, customizationAreas, orderNumber }) => (
  <>
    <Typography variant="h6" className={classes.templateCaption}>
      {orderNumber} - Review Summary
    </Typography>
    {customizationAreas.map((customizationArea, index) => (
      <SummaryDetailView
        key={customizationArea.id}
        customizationArea={customizationArea}
        index={index + 1}
        classes={classes}
      />
    ))}
  </>
);

const CustomizationAreaDetailView = ({ classes, customizationArea, index }) => {
  const { method, gangsheet, width, height, quantity } = customizationArea;
  const product =
    method === "TRANSFER_STANDARD"
      ? "DTF Standard Transfers"
      : method === "TRANSFER_GLITTER"
        ? "DTF Glitter Transfers"
        : method === "TRANSFER_UV"
          ? "UV DTF 3D PermaStickers™"
          : null;
  const label = `${product} ${gangsheet ? "Gangsheet" : "by Size"} ${width}" x ${height}"`;

  const notes = customizationArea.notesCollection;

  const customerArtwork = customizationArea.customerArtwork[0];
  const artwork = customizationArea.productionArtwork[0];

  const customerArtworkUrl = customerArtwork?.file?.asset?.url?.formatted;
  const artworkUrl = artwork?.remoteAsset?.url?.formatted;

  return (
    <div>
      <Typography className={classes.templateCaption} variant="h6">
        {label}
      </Typography>
      {notes.length > 0 && (
        <div className={classes.notesContainer}>
          <Typography className={classes.customizationAreaDetail}>
            Notes:
          </Typography>
          <ul className={classes.notesList}>
            {notes.map((note, index) => (
              <li key={index}>
                {note.author}: {note.note}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className={classes.divider} />
      <div className={classes.customizationAreaContainer}>
        <div className={classes.customizationAreaImageItem}>
          <Typography variant="h6">ORIGINAL:</Typography>
          <div>
            {customerArtworkUrl ? (
              <a
                className={classes.artworkUrl}
                href={customerArtworkUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.previewImage}
                  src={customerArtworkUrl}
                  alt="customerArtworkUrl"
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #b1b1b1 25%, transparent 25%), linear-gradient(-45deg, #b1b1b1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b1b1b1 75%), linear-gradient(-45deg, transparent 75%, #b1b1b1 75%)",
                    backgroundSize: "20px 20px",
                    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px"
                  }}
                />
              </a>
            ) : (
              <Typography className={classes.missingArtworkLabel}>
                Missing artwork
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.customizationAreaImageItem}>
          <Typography variant="h6">OUTPUT:</Typography>
          <div>
            {artworkUrl ? (
              <a
                className={classes.artworkUrl}
                href={artworkUrl}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.previewImage}
                  src={artworkUrl}
                  alt="artworkUrl"
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #b1b1b1 25%, transparent 25%), linear-gradient(-45deg, #b1b1b1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #b1b1b1 75%), linear-gradient(-45deg, transparent 75%, #b1b1b1 75%)",
                    backgroundSize: "20px 20px",
                    backgroundPosition: "0 0, 0 10px, 10px -10px, -10px 0px"
                  }}
                />
              </a>
            ) : (
              <Typography className={classes.missingArtworkLabel}>
                Missing artwork
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Typography variant="subtitle1">
        {`Line Item: ${index + 1}. Quantity: ${quantity || "---"}`}{" "}
        {customerArtworkUrl && <a href={customerArtworkUrl}>(zoom)</a>}
      </Typography>
    </div>
  );
};

export default enhancer(ArtReviewOrderDetails);
